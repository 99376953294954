"use client";

import { useAuth } from "@/hooks/auth";
import Script from "next/script";
import { useEffect, useState } from "react";
import React from "react";
interface CustomWindow extends Window {
  heap?: any;
}

const HeapAnalytics: React.FC = () => {
  const { user } = useAuth();
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const heapKey =
    process.env.VERCEL_ENV == "production"
      ? process.env.NEXT_PUBLIC_HEAP_ANALYTICS_PROD
      : process.env.NEXT_PUBLIC_HEAP_ANALYTICS_DEV;

  useEffect(() => {
    const customWindow = window as CustomWindow;

    if (customWindow?.heap && user?.email && user?.features) {
      const userId = user.email || "guest";
      customWindow.heap.identify(userId);

      const { displayName, email, features, isStaff } = user;
      const dataForHeap = {
        name: displayName,
        userId: email,
        ...features,
      };

      customWindow.heap.addUserProperties(dataForHeap);
    }
  }, [scriptLoaded, user, heapKey]);

  const heapScript = `
    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
    heap.load(${heapKey});
  `;

  const scriptReady = () => {
    console.log("Heap script loaded");
    const customWindow = window as CustomWindow;

    if (customWindow.heap && heapKey?.length) {
      setScriptLoaded(true);
    }
  };

  return (
    <Script
      id="heap-analytics"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{ __html: heapScript }}
      onReady={scriptReady}
    />
  );
};

export default HeapAnalytics;
